import getPubs from './getPubs'

export default async function rightBlock(context){
    return new Promise(async(resolve, reject) => {
        let categoryID;
        let idRubrique = context.rubriqueData?.idRubrique;
        let activePage = context.activePage;
        let selectedPubs = [];

        let pubs = await getPubs('right',true,false);

        switch(activePage){
            case 'home' : 
                categoryID = 1;
                selectedPubs.push(pubs.filter(pub=>pub.id==1)[0]);

                break;
            case 'aroundme' : 
                categoryID=2;
                break;
            default :
                categoryID = context.thematiques.items.find(el => el.id_rubrique == idRubrique)?.id;
                selectedPubs.push(pubs.filter(pub=>pub.id==1)[0]);

                break;
        }

        if(categoryID){
            selectedPubs= selectedPubs.concat(pubs.filter(el=>el.categories.includes(categoryID)));    
        }
        resolve(selectedPubs.filter(e=>e))

    });
}
